var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticStyle:{"padding-right":"0px","max-height":"95vh"},attrs:{"id":"winbackTimelineModal","hide-footer":"","hide-header":"","body-class":"p-0"},on:{"hide":_vm.hidden}},[_c('div',{staticClass:"modal-card card"},[_c('div',{staticClass:"card-header d-flex flex-column justify-content-center",staticStyle:{"align-items":"inherit"}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col pl-0 ml-0"},[_c('h4',{staticClass:"card-header-title text-capitalize"},[_c('button',{staticClass:"btn pl-0 pr-1 ml-0 arrow-button",on:{"click":function($event){return _vm.close()}}},[_c('span',{staticClass:"fe fe-chevron-left"})]),_c('div',{staticClass:"mr-2 d-inline-block"},[(!_vm.selectIsLoading(['SET_ACTIVE_CONVERSATION']))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn-pointer",attrs:{"title":_vm._f("phone")(_vm.customerPhone)}},[_vm._v(" "+_vm._s(_vm.customerName)+" ")]):_vm._e()])])]),(_vm.selectActiveConversation && _vm.selectActiveConversation._id)?_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title: _vm.selectActiveConversation.resolved
                ? 'You can reopen this to keep a close eye on it.'
                : 'You\'re safe to mark this as resolved. If they reply, it will automatically re-open.',
              placement: 'top',
              trigger: 'hover',
              variant: 'primary',
            }),expression:"{\n              title: selectActiveConversation.resolved\n                ? 'You can reopen this to keep a close eye on it.'\n                : 'You\\'re safe to mark this as resolved. If they reply, it will automatically re-open.',\n              placement: 'top',\n              trigger: 'hover',\n              variant: 'primary',\n            }"}],staticClass:"btn btn-sm d-inline-block mr-2",class:{
              'btn-primary': _vm.highlightResolve,
              'btn-white': !_vm.highlightResolve,
            },attrs:{"disabled":_vm.settingActiveConversation || _vm.resolveLoader,"id":"resolveButton"},on:{"click":function($event){_vm.$emit(
                _vm.selectActiveConversation.resolved ? 'unresolve' : 'resolve',
                _vm.selectActiveConversation
              )
              _vm.toggleResolve()}}},[(_vm.resolveLoader)?_c('div',{staticClass:"container d-flex justify-content-center align-items-center"},[_c('b-spinner',{attrs:{"small":"","variant":"primary"}})],1):_c('span',[_vm._v(_vm._s(_vm.selectActiveConversation.resolved ? 'Reopen' : 'Resolve'))])]),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title: `${_vm.blocked ? 'Unblocks' : 'Blocks'} future messages from this customer.`,
              placement: 'top',
              trigger: 'hover',
              variant: 'primary',
            }),expression:"{\n              title: `${blocked ? 'Unblocks' : 'Blocks'} future messages from this customer.`,\n              placement: 'top',\n              trigger: 'hover',\n              variant: 'primary',\n            }"}],staticClass:"btn btn-sm d-inline-block mr-2",class:{
              'btn-white': !_vm.blocked,
              'btn-danger': _vm.blocked,
            },attrs:{"disabled":_vm.settingActiveConversation || _vm.blockLoader},on:{"click":function($event){return _vm.$emit(_vm.blocked ? 'unblock' : 'block', _vm.selectActiveConversation)}}},[(_vm.blockLoader)?_c('b-spinner',{attrs:{"small":"","variant":"primary"}}):_c('i',{staticClass:"fe fe-user-x"})],1),_c('button',{staticClass:"close d-inline-block mt-1",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"overflow":"auto"}},_vm._l((_vm.selectTags),function(tag){return _c('div',{key:tag._id,staticClass:"badge badge-light mr-2"},[_vm._v(" "+_vm._s(tag.name)+" ")])}),0)]),(_vm.settingActiveConversation)?_c('div',{staticClass:"container d-flex justify-content-center align-items-center",staticStyle:{"min-height":"55vh"}},[_c('b-spinner',{attrs:{"large":"","variant":"primary"}})],1):_c('TimelineBody',{attrs:{"limit":_vm.limit}})],1),(!_vm.settingActiveConversation)?_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.selectActiveCustomer && _vm.selectActiveConversation && _vm.location)?_c('div',{staticClass:"col-12",style:(_vm.composeMessageStyle)},[(_vm.location.bandwidth)?_c('ComposeMessage',{ref:"composer",attrs:{"prepopulateWith":_vm.contactText,"customer":_vm.selectActiveCustomer,"location":_vm.location,"company":_vm.company},on:{"sent":function($event){return _vm.sendMessageReply($event)},"typing":function($event){return _vm.$root.$emit('bv::hide::tooltip')}}}):_vm._e(),(!_vm.location.bandwidth)?_c('div',{staticClass:"alert alert-light fade show mb-0",attrs:{"role":"alert"}},[_c('strong',[_vm._v("Uh-oh!")]),_vm._v(" SMS capabilities are not enabled for your "+_vm._s(_vm.location.friendlyName || _vm.location.city)+" location. "),_c('a',{attrs:{"href":"mailto:hello@ovationup.com"}},[_vm._v("Contact us")]),_vm._v(" to enable them. ")]):_vm._e()],1):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }