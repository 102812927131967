<template>
  <div class="message" v-if="order && integration && location">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-2">
        <Avatar :img="integrationDetails.img" size="xs" class="timeline-integration-avatar" />
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div v-show="orderNumber" class="order-number">{{ orderNumber }}</div>
          <div class="comment-text" role="alert">
            <span>
              Spent
              <strong>{{ orderTotal | currency }}</strong>
              <span v-if="items">
                on
                <span>{{ items }}</span>
              </span>
              via {{ integrationDetails.name }}
            </span>
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Order</span>
            <div class="row">
              <div class="col-auto">
                <p class="comment-time">
                  {{ created | moment('LT') }},
                  {{ location.friendlyName || location.city }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { map } from 'lodash-es'
import Avatar from '@/components/MainContent/Avatar'
import { getIntegrationDetails, availableIntegrations } from '@/lib/modules/integration'
import { createNamespacedHelpers } from 'vuex'
import { convertS3toAssetUrl } from '@/lib/format'
const IntegrationModule = createNamespacedHelpers('integration')
const IntegrationProviderModule = createNamespacedHelpers('integrationProvider')

export default {
  name: 'OrderEvent',
  props: ['created', 'integration', 'location', 'order'],
  components: {
    Avatar,
  },
  data: () => ({
    integrationProviderId: null,
  }),
  computed: {
    ...IntegrationProviderModule.mapGetters(['selectIntegrationProviderById']),
    integrationDetails() {
      let name = null
      if (
        this.integrationProviderId &&
        this.selectIntegrationProviderById(this.integrationProviderId)
      ) {
        let { displayName, logo } = this.selectIntegrationProviderById(this.integrationProviderId)
        const img = convertS3toAssetUrl(logo)
        return { name: displayName, img }
      } else {
        name = this.integration?.provider
      }

      return getIntegrationDetails(name) || {}
    },
    orderNumber() {
      const { posOrderNumber, orderProvider } = this.order
      return posOrderNumber && posOrderNumber.length < 20
        ? `${orderProvider ? `${orderProvider} ` : ''}ORDER #${posOrderNumber}`
        : ''
    },
    items() {
      const items = this.order.items
      if (!items.length) return null
      return items.reduce((itemString, item, index) => {
        if (itemString) {
          itemString += ', '
          if (index === items.length - 1) itemString += 'and '
        }
        if (item.quantity > 1) itemString += `(${item.quantity}) `
        return itemString + item.name
      }, '')
    },
    orderTotal() {
      const total =
        '$numberDecimal' in this.order.total ? this.order.total.$numberDecimal : this.order.total

      return this.integration.provider === availableIntegrations.skipTheDishes().provider
        ? total / 100
        : total
    },
  },
  methods: {
    ...IntegrationModule.mapActions(['getIntegration']),
  },
  async mounted() {
    if (!Object.keys(this.integrationDetails).length) {
      const response = await this.getIntegration(this.integration._id)
      this.integrationProviderId = response.body.data.integration.integrationProvider
    }
  },
}
</script>

<style lang="scss" scoped>
.order-number {
  font-weight: bold;
}
</style>
