<template>
  <div>
    <div v-if="shouldCelebrate" class="celebration-banner form-control text-center">
      <span>Great job responding quickly!</span>
    </div>
    <div
      class="card-body"
      id="timelineBody"
      @scroll="updateTimelineScroll"
      style="min-height: 55vh"
    >
      <div class="row">
        <div class="quick-response-wrapper">
          <canvas
            id="timelineConfetti"
            style="position: absolute; width: 90%; height: 75%"
          ></canvas>
        </div>
        <div class="col-12">
          <div class="timeline" v-if="selectTimelineEvents.length" id="timelineList">
            <div class="container d-flex justify-content-center align-items-center">
              <b-spinner
                small
                variant="primary"
                v-show="loading && selectTimelineScrollDirection == 'previous'"
              />
            </div>
            <div v-for="eventGroup of selectTimelineEvents" :key="eventGroup.timestamp">
              <!-- timestamp for grouped events -->
              <div v-if="eventGroup" class="text-center mb-2 mt-1">
                <span style="font-size: 10px">
                  {{ eventGroup.timestamp }}
                </span>
              </div>
              <!-- end timestamp -->

              <div
                v-for="event of eventGroup.events"
                :key="event._id"
                class="row"
                :class="{
                  'justify-content-end':
                    event.user ||
                    event.type === 'sent-campaign' ||
                    event.type === 'sent-custom-question-list' ||
                    event.type === 'sent-promo',
                }"
              >
                <OrderEvent
                  v-if="event.type === 'order'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :integration="selectIntegrationById(event.integration)"
                  :location="selectLocationById(event.location)"
                  :order="selectOrderById(event.order)"
                />
                <CampaignEvent
                  v-if="event.type === 'sent-campaign'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :campaign="selectCampaignById(event.campaign)"
                />
                <CheckinEvent
                  v-else-if="event.type === 'checkin' && event.location"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :location="selectLocationById(event.location)"
                />
                <LoyaltyEvent
                  v-else-if="event.type === 'earned-points' || event.type === 'spent-points'"
                  :id="`timeline-${event._id}`"
                  :type="event.type"
                  :created="event.created"
                  :loyalty="selectLoyaltyById(event.loyalty)"
                  :pointLabel="company ? company.loyalty.pointLabel : null"
                />
                <FeedbackEvent
                  v-else-if="selectActiveConversation && event.type === 'left-feedback'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :survey="selectSurveyById(event.survey)"
                  :user="selectUserById(event.user)"
                  :conversationLastSurvey="selectActiveConversation.lastSurvey"
                />
                <LeftReviewEvent
                  v-else-if="event.type === 'left-review'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                />
                <MessageEvent
                  v-else-if="
                    selectActiveConversation &&
                    (event.type === 'outgoing-message' || event.type === 'incoming-message')
                  "
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :message="selectMessageById(event.message)"
                  :user="selectUserById(event.user)"
                  :conversationLastMessage="selectActiveConversation.lastMessage"
                />
                <PromoRedeemEvent
                  v-else-if="event.type === 'redeem-promo'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :promo="selectPromoById(event.promo)"
                  :customerId="selectActiveCustomer ? selectActiveCustomer._id : null"
                />
                <PromoSentEvent
                  v-else-if="event.type === 'sent-promo'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :promo="selectPromoById(event.promo)"
                  :user="selectUserById(event.user)"
                />
                <SurveyEvent
                  v-else-if="event.type === 'left-survey'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :survey="selectSurveyById(event.survey)"
                  :location="selectLocationById(event.location)"
                />
                <CompletedCustomQuestionListEvent
                  v-else-if="event.type === 'completed-custom-question-list'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                />
                <SentCustomQuestionListEvent
                  v-else-if="event.type === 'sent-custom-question-list'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                />
              </div>
            </div>
            <div
              v-if="loading && selectTimelineScrollDirection == 'next'"
              class="container d-flex justify-content-center align-items-center"
            >
              <b-spinner small variant="primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'confetti-js'
import CampaignEvent from './TimelineEvents/CampaignEvent'
import CheckinEvent from './TimelineEvents/CheckinEvent'
import OrderEvent from './TimelineEvents/OrderEvent'
import SurveyEvent from './TimelineEvents/SurveyEvent'
import FeedbackEvent from './TimelineEvents/FeedbackEvent'
import LeftReviewEvent from './TimelineEvents/LeftReviewEvent'
import MessageEvent from './TimelineEvents/MessageEvent'
import PromoRedeemEvent from './TimelineEvents/PromoRedeemEvent'
import PromoSentEvent from './TimelineEvents/PromoSentEvent'
import LoyaltyEvent from './TimelineEvents/LoyaltyEvent'
import CompletedCustomQuestionListEvent from './TimelineEvents/CompletedCustomQuestionListEvent'
import SentCustomQuestionListEvent from './TimelineEvents/SentCustomQuestionListEvent'
import { createNamespacedHelpers, mapGetters } from 'vuex'

const WinbackModule = createNamespacedHelpers('winback')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'TimelineBody',
  components: {
    CampaignEvent,
    CheckinEvent,
    FeedbackEvent,
    LeftReviewEvent,
    MessageEvent,
    PromoRedeemEvent,
    PromoSentEvent,
    SurveyEvent,
    OrderEvent,
    LoyaltyEvent,
    CompletedCustomQuestionListEvent,
    SentCustomQuestionListEvent,
  },
  data() {
    return {
      celebrationTimeout: null,
    }
  },
  computed: {
    ...mapGetters(['selectIsLoading']),
    ...WinbackModule.mapGetters([
      'selectActiveConversation',
      'selectTimelineEvents',
      'selectMessageById',
      'selectTimelineScrollDirection',
      'selectSurveyById',
      'selectActiveCustomer',
      'selectLoyaltyById',
      'selectOrderById',
      'selectIsWithinCelebrationTime',
    ]),
    ...DataCoreModule.mapGetters([
      'selectCampaignById',
      'selectCompanyById',
      'selectIntegrationById',
      'selectLocationById',
      'selectPromoById',
      'selectUserById',
    ]),
    loading() {
      return this.selectIsLoading(['FETCH_TIMELINE'])
    },
    blocked() {
      return this.selectActiveCustomer &&
        this.selectActiveCustomer.blocked &&
        this.company &&
        this.selectActiveCustomer.blocked.includes(this.company._id)
        ? true
        : false
    },
    company() {
      return this.selectActiveConversation
        ? this.selectCompanyById(this.selectActiveConversation.company)
        : null
    },
    shouldCelebrate() {
      return this.selectIsWithinCelebrationTime
    },
  },
  methods: {
    ...WinbackModule.mapActions([
      'clearActiveTimeline',
      'fetchMoreTimelineEvents',
      'updateTimelineScroll',
      'setisWithinCelebrationTime',
    ]),

    showConfetti() {
      const confettiSettings = {
        size: 1.5,
        target: 'timelineConfetti',
      }
      const confetti = new ConfettiGenerator(confettiSettings)
      confetti.render()
      return confetti
    },
  },
  watch: {
    shouldCelebrate: {
      handler(value) {
        if (value) {
          const confetti = this.showConfetti()
          this.celebrationTimeout = setTimeout(() => {
            confetti.clear()
            this.setisWithinCelebrationTime(false)
          }, 10000) // show for 10 seconds
        }
      },
    },
  },
  destroyed() {
    if (this.celebrationTimeout) {
      clearTimeout(this.celebrationTimeout)
      this.setisWithinCelebrationTime(false)
    }
  },
}
</script>

<style scoped lang="scss">
.arrow-button {
  background: transparent !important;

  span {
    top: -2px;
    position: relative;
    opacity: 0.6;
    font-size: 18px;
    &:hover {
      opacity: 1;
    }
  }
}
.btn-pointer {
  cursor: pointer;
}
.celebration-banner {
  border-radius: 0px;
  background-color: #a8f0cb;
}
</style>
