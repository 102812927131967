<template>
  <b-modal
    id="winbackTimelineModal"
    hide-footer
    hide-header
    @hide="hidden"
    body-class="p-0"
    style="padding-right: 0px; max-height: 95vh"
  >
    <div class="modal-card card">
      <div
        class="card-header d-flex flex-column justify-content-center"
        style="align-items: inherit"
      >
        <div class="row align-items-center">
          <div class="col pl-0 ml-0">
            <h4 class="card-header-title text-capitalize">
              <button @click="close()" class="btn pl-0 pr-1 ml-0 arrow-button">
                <span class="fe fe-chevron-left"></span>
              </button>
              <div class="mr-2 d-inline-block">
                <span
                  class="btn-pointer"
                  v-b-tooltip.hover.bottom
                  :title="customerPhone | phone"
                  v-if="!selectIsLoading(['SET_ACTIVE_CONVERSATION'])"
                >
                  {{ customerName }}
                </span>
              </div>
            </h4>
          </div>
          <div class="col-auto" v-if="selectActiveConversation && selectActiveConversation._id">
            <button
              :disabled="settingActiveConversation || resolveLoader"
              @click="
                $emit(
                  selectActiveConversation.resolved ? 'unresolve' : 'resolve',
                  selectActiveConversation
                )
                toggleResolve()
              "
              class="btn btn-sm d-inline-block mr-2"
              :class="{
                'btn-primary': highlightResolve,
                'btn-white': !highlightResolve,
              }"
              id="resolveButton"
              v-b-tooltip="{
                title: selectActiveConversation.resolved
                  ? 'You can reopen this to keep a close eye on it.'
                  : 'You\'re safe to mark this as resolved. If they reply, it will automatically re-open.',
                placement: 'top',
                trigger: 'hover',
                variant: 'primary',
              }"
            >
              <div
                v-if="resolveLoader"
                class="container d-flex justify-content-center align-items-center"
              >
                <b-spinner small variant="primary" />
              </div>
              <span v-else>{{ selectActiveConversation.resolved ? 'Reopen' : 'Resolve' }}</span>
            </button>
            <button
              :disabled="settingActiveConversation || blockLoader"
              class="btn btn-sm d-inline-block mr-2"
              @click="$emit(blocked ? 'unblock' : 'block', selectActiveConversation)"
              :class="{
                'btn-white': !blocked,
                'btn-danger': blocked,
              }"
              v-b-tooltip="{
                title: `${blocked ? 'Unblocks' : 'Blocks'} future messages from this customer.`,
                placement: 'top',
                trigger: 'hover',
                variant: 'primary',
              }"
            >
              <b-spinner v-if="blockLoader" small variant="primary" />
              <i class="fe fe-user-x" v-else></i>
            </button>
            <button @click="close" type="button" class="close d-inline-block mt-1">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="d-flex align-items-center" style="overflow: auto">
          <div v-for="tag in selectTags" :key="tag._id" class="badge badge-light mr-2">
            {{ tag.name }}
          </div>
        </div>
      </div>
      <div
        v-if="settingActiveConversation"
        style="min-height: 55vh"
        class="container d-flex justify-content-center align-items-center"
      >
        <b-spinner large variant="primary" />
      </div>
      <TimelineBody :limit="limit" v-else />
    </div>
    <div class="modal-footer" v-if="!settingActiveConversation">
      <div class="container">
        <div class="row">
          <div
            class="col-12"
            v-if="selectActiveCustomer && selectActiveConversation && location"
            :style="composeMessageStyle"
          >
            <ComposeMessage
              ref="composer"
              :prepopulateWith="contactText"
              :customer="selectActiveCustomer"
              :location="location"
              :company="company"
              v-if="location.bandwidth"
              @sent="sendMessageReply($event)"
              @typing="$root.$emit('bv::hide::tooltip')"
            />
            <div v-if="!location.bandwidth" class="alert alert-light fade show mb-0" role="alert">
              <strong>Uh-oh!</strong>
              SMS capabilities are not enabled for your
              {{ location.friendlyName || location.city }} location.
              <a href="mailto:hello@ovationup.com">Contact us</a> to enable them.
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import TimelineBody from './TimelineBody.vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { bootIntercom, shutdownIntercom } from '@/lib/analytics'
import ComposeMessage from '@/components/Modules/WinbackV2/ComposeMessage'
import { disablePageScroll, enablePageScroll, addLockableTarget } from 'scroll-lock'

const WinbackModule = createNamespacedHelpers('winback')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'TimelineModal',
  props: ['limit'],
  components: {
    TimelineBody,
    ComposeMessage,
  },
  data: () => ({
    highlightResolve: false,
    contactText: '',
  }),
  computed: {
    ...WinbackModule.mapGetters(['selectActiveConversation', 'selectActiveCustomer', 'selectTags']),
    ...DataCoreModule.mapGetters(['selectCompanyById', 'selectLocationById']),
    ...mapGetters(['selectIsLoading']),
    settingActiveConversation() {
      return this.selectIsLoading(['SET_ACTIVE_CONVERSATION'])
    },
    blockLoader() {
      return this.selectIsLoading(['BLOCK_CUSTOMER', 'UNBLOCK_CUSTOMER'])
    },
    resolveLoader() {
      return this.selectIsLoading([
        `RESOLVE_CONVERSATION-${this.selectActiveConversation._id}`,
        `UNRESOLVE_CONVERSATION-${this.selectActiveConversation._id}`,
      ])
    },
    blocked() {
      return this.selectActiveCustomer &&
        this.selectActiveCustomer.blocked &&
        this.company &&
        this.selectActiveCustomer.blocked.includes(this.company._id)
        ? true
        : false
    },
    location() {
      return this.selectActiveConversation
        ? this.selectLocationById(this.selectActiveConversation.location)
        : null
    },
    company() {
      return this.selectActiveConversation
        ? this.selectCompanyById(this.selectActiveConversation.company)
        : null
    },
    customerName() {
      return this.selectActiveCustomer && this.selectActiveCustomer.name
        ? this.selectActiveCustomer.name
        : this.selectActiveConversation && this.selectActiveConversation.name
        ? this.selectActiveConversation.name
        : 'Anonymous'
    },
    customerPhone() {
      return this.selectActiveCustomer && this.selectActiveCustomer.phone
        ? this.selectActiveCustomer.phone
        : this.selectActiveConversation && this.selectActiveConversation.phone
        ? this.selectActiveConversation.phone
        : null
    },
    composeMessageStyle() {
      return this.selectIsLoading(['SEND_REPLY', 'CREATE_CONVERSATION'])
        ? {
            'pointer-events': 'none',
            opacity: 0.5,
          }
        : {}
    },
  },
  methods: {
    ...WinbackModule.mapActions(['clearActiveTimeline', 'sendReply', 'createConversation']),
    hidden(evt) {
      if (this.selectIsLoading(['SET_ACTIVE_CONVERSATION'])) {
        evt.preventDefault()
        return false
      }

      this.clearActiveTimeline()
      // Unlock scrolling when modal closes
      enablePageScroll()
      
      // remove customerId from query params
      window.history.replaceState({} , null, this.$route.path.split('?')[0])
    },
    close() {
      bootIntercom(this.$intercom)
      this.$bvModal.hide('winbackTimelineModal')
    },
    toggleResolve() {
      // if the conversation was not resolved
      // and you just clicked resolve, then close the modal
      if (!this.selectActiveConversation.resolved) {
        this.close()
      }
    },
    async sendMessageReply({ message, promoId }) {
      if (!this.selectActiveConversation._id) {
        await this.createConversation({
          locationId: this.location._id,
          customerId: this.selectActiveCustomer._id,
          companyId: this.selectActiveConversation.company,
        })
      }

      await this.sendReply({ promoId, message })

      this.highlightResolve = true
      setTimeout(() => {
        this.$root.$emit('bv::show::tooltip', 'resolveButton')
      }, 2000)
    },
  },
}
</script>

<style scoped lang="scss">
.arrow-button {
  background: transparent !important;

  span {
    top: -2px;
    position: relative;
    opacity: 0.6;
    font-size: 18px;
    &:hover {
      opacity: 1;
    }
  }
}
.btn-pointer {
  cursor: pointer;
}
</style>
