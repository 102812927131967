import { render, staticRenderFns } from "./FeedbackEvent.vue?vue&type=template&id=07f8a2ce&scoped=true"
import script from "./FeedbackEvent.vue?vue&type=script&lang=js"
export * from "./FeedbackEvent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f8a2ce",
  null
  
)

export default component.exports