<template>
  <div class="message" v-if="campaign">
    <div class="row mt-1 mb-1 message internal">
      <div class="col mr--2">
        <div class="comment-body">
          <p class="comment-text text-capitalize">
            Received
            <span class="dotted-underline mr-1" v-b-popover.hover.bottom="message">
              {{ campaign.title }}
            </span>
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Campaign</span>
          </p>
          <div class="row">
            <div class="col-auto">
              <p class="comment-time">
                {{ created | moment('LT') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto mt-2">
        <a href="javascript:;" class="btn btn-sm btn-rounded-circle btn-primary"
          ><i class="fe fe-message-square icon-inner-adjust"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignEvent',
  props: ['campaign', 'created'],
  computed: {
    message() {
      return this.campaign && this.campaign.messageBody
    },
  },
}
</script>

<style scoped>
.dotted-underline {
  text-decoration: underline dotted white;
}
</style>
