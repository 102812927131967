<template>
  <div v-if="survey && survey.feedback">
    <Message
      :content="content"
      :user="user || {}"
      :timestamp="created"
      :highlights="highlights"
      type="incoming"
      label="Feedback"
    ></Message>
  </div>
</template>

<script>
import Message from '@/components/Modules/Message/Message'
export default {
  name: 'FeedbackEvent',
  props: ['created', 'survey', 'user', 'conversationLastSurvey'],
  components: {
    Message,
  },
  computed: {
    highlights() {
      return (
        this.survey &&
        this.conversationLastSurvey &&
        this.conversationLastSurvey.score &&
        this.survey._id == this.conversationLastSurvey._id
      )
    },
    content() {
      if (this.highlights) {
        let body = this.conversationLastSurvey.feedback
        let highlights = this.conversationLastSurvey.highlights
        if (highlights && highlights.length) {
          highlights.forEach((highlight) => {
            let replacements = highlight.texts
              .map((text) => {
                if (text.type === 'hit') {
                  return '<mark>' + text.value + '</mark>'
                } else {
                  return text.value
                }
              })
              .join('')

            let originals = highlight.texts
              .map((text) => {
                return text.value
              })
              .join('')

            body = body.replace(originals, replacements)
          })
        }
        return body
      }

      return this.survey.feedback
    },
  },
}
</script>

<style scoped lang="scss"></style>
