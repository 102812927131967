<template>
  <div>
    <div class="row">
      <div class="col-auto" v-if="showAvatar">
        <!-- Avatar -->
        <Avatar
          :img="onlineUser.avatar"
          :first-name="onlineUser.firstName"
          :last-name="onlineUser.lastName"
          size="sm"
          status="online"
        />
      </div>
      <div class="col ml--2">
        <!-- Input -->
        <div>
          <label class="sr-only">Leave a comment...</label>
          <div class="input-group">
            <textarea-autosize
              v-model="message"
              @input="typing()"
              ref="messageInput"
              class="form-control message-input"
              placeholder="Message"
              rows="1"
            ></textarea-autosize>
            <div class="input-group-append">
              <button
                class="btn btn-primary btn-sm float-right"
                :disabled="message.length < 3"
                @click="sendMessage()"
              >
                <span class="fe fe-send"></span>
                Send
              </button>
            </div>
          </div>
        </div>

        <div class="row align-items-right mt-3">
          <div class="col">
            <div>
              <PromoDropdown
                class="d-inline-block mr-2 mb-2"
                ref="promoDropdown"
                v-on:add="newPromo"
                v-on:selected="addPromo"
                v-on:reset="resetPromo"
                :includeUniquePromos="true"
                :includeModal="true"
                source="winback"
                :activeConversation="selectActiveConversation"
              />
              <TemplateDropdown
                class="d-inline-block mr-2 mb-2"
                ref="templateDropdown"
                v-on:add="newTemplate"
                v-on:selected="addTemplate"
                v-on:reset="resetTemplate"
                :includeModal="true"
                :activeConversation="selectActiveConversation"
              />
              <LoyaltyDropdown
                v-if="companyLoyaltyEnabled"
                :customer="customer"
                :company="company"
                :location="location"
                class="d-inline-block mr-2 mb-2"
                ref="loyaltyDropdown"
                @sentPoints="sentPoints"
              />
              <SendQuestionListButton
                class="d-inline-block mr-2 mb-2"
                v-if="showSendListButton"
                :activeConversation="selectActiveConversation"
              />
            </div>
          </div>
          <div class="col-auto">
            <p class="text-muted small mt-1 d-none d-sm-inline-block">
              {{ message.length }} characters
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateService from '@/services/TemplateService'
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const WinbackModule = createNamespacedHelpers('winback')
const DataCoreModule = createNamespacedHelpers('dataCore')
import PromoDropdown from '@/components/Modules/Dropdowns/PromoDropdown'
import TemplateDropdown from '@/components/Modules/Dropdowns/TemplateDropdown'
import LoyaltyDropdown from '@/components/Modules/Loyalty/LoyaltyDropdown'
import SendQuestionListButton from '@/components/Modules/WinbackV2/SendQuestionListButton'
import Avatar from '@/components/MainContent/Avatar'

export default {
  name: 'ComposeMessage',
  props: ['prepopulateWith', 'customer', 'location', 'company'],
  components: {
    Avatar,
    PromoDropdown,
    TemplateDropdown,
    LoyaltyDropdown,
    SendQuestionListButton,
  },
  data: () => ({
    message: '',
    showAvatar: true,
    promoId: undefined,
    emitScrollLength: 100,
    emitScrollStep: 15,
  }),
  computed: {
    ...DataCoreModule.mapGetters(['selectCompanyById']),
    ...UserModule.mapGetters(['onlineUser']),
    ...WinbackModule.mapGetters(['selectActiveConversation']),
    showSendListButton() {
      // TODO change to feature flag. For now only show for Zack's Shack and Ovation Dev
      const companyShowList = [
        '5c17bb7fc1a27231b61e71d6',
        '5d02afeee2e45649bb4b56fb',
        '5d31ed243e1a6d3b9f8c6387',
      ]
      if (
        this.selectActiveConversation &&
        companyShowList.includes(this.selectActiveConversation.company)
      ) {
        return true
      } else {
        return false
      }
    },
    companyLoyaltyEnabled() {
      return this.selectCompanyById(this.selectActiveConversation?.company)?.loyalty?.enabled
        ? true
        : false
    },
  },
  methods: {
    addPromo(promo) {
      this.promoId = promo._id
    },
    async addTemplate(template) {
      if (!template.promo && template.promptEachTime) {
        this.confirmAddPromo()
      } else if (template.promo) {
        this.$refs.promoDropdown.openPromoDropdown(template.promo)
      }
      const response = await TemplateService.interpolate({
        companyId: this.companyId,
        userId: this.onlineUser._id,
        locationId: this.location._id,
        customerId: this.customer._id,
        input: template.body,
      })
      this.message = response.body.output
    },
    newPromo() {
      this.$emit('newPromo')
    },
    newTemplate() {
      this.$emit('newTemplate')
    },
    sentPoints() {
      this.$emit('sentPoints')
    },
    resetPromo() {
      this.promoId = undefined
    },
    resetTemplate() {
      this.message = ''
    },
    async sendMessage() {
      this.$emit('sent', { message: this.message, promoId: this.promoId })
      this.message = ''
      this.$refs.promoDropdown.reset()
      this.$refs.templateDropdown.reset()
    },
    typing() {
      this.$emit('typing')
    },
    focusTextarea() {
      const context = this
      if (this.prepopulateWith) {
        this.message = this.prepopulateWith
      } else {
        this.message = ''
      }

      let focusInterval = setInterval(() => {
        if (context.$refs.messageInput) {
          context.$refs.messageInput.$el.focus()
          clearInterval(focusInterval)
        }
      }, 100)
    },
    confirmAddPromo() {
      this.$bvModal
        .msgBoxConfirm('Would you like to add an offer to this response?', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          cancelVariant: 'default',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$refs.promoDropdown.openPromoDropdown()
          }
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            text: 'Something went wrong, please try again.',
          })
        })
    },
  },
  mounted() {
    this.focusTextarea()
  },
  watch: {
    // message: function (val) {
    //   if (val.length > this.emitScrollLength) {
    //     this.$emit('scrollBottom')
    //     this.emitScrollLength += this.emitScrollStep
    //   }
    // },
    // prepopulateWith: function (val) {
    //   this.message = val
    // },
  },
}
</script>

<style scoped lang="scss">
.message {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}
.message-input {
  min-height: 40px;
}
</style>
