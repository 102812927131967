<template>
  <div class="message">
    <div class="row mt-1 mb-1 message internal">
      <div class="col mr--2">
        <div class="comment-body">
          <p class="comment-text text-capitalize">Sent Custom Question List</p>
          <div class="row">
            <div class="col-auto">
              <p class="comment-time">
                {{ created | moment('LT') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto mt-1">
        <a href="javascript:;" class="btn btn-sm btn-rounded-circle btn-primary"
          ><i class="fas fa-list-ul icon-inner-adjust"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SentCustomQuestionListEvent',
  props: ['created'],
}
</script>
