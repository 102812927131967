<template>
  <div>
    <b-dropdown ref="dropdown" variant="white" size="sm" class="hide-caret">
      <template slot="button-content">
        <span> <i class="fas fa-gift ml-1" style="position: relative; top: 1px"></i>&nbsp; </span>
      </template>
      <b-dropdown-item
        v-for="point in points"
        :key="point"
        class="dropdown-item"
        @click="sendPoints(point)"
        >Give {{ point }} point{{ point === 1 ? '' : 's' }}</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-form style="width: 225px" class="px-3">
        <b-input-group>
          <b-form-input
            v-model="customPoints"
            type="number"
            placeholder="Specify points"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="sendPoints(customPoints)" variant="primary">
              <i class="far fa-paper-plane" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoyaltyDropdown',
  props: ['customer', 'company', 'location'],
  data: () => ({
    customPoints: undefined,
    points: [1, 2, 5, 10],
  }),
  methods: {
    ...mapActions('loyalty', ['sendLoyaltyPoints']),
    async sendPoints(points) {
      await this.sendLoyaltyPoints({
        customerId: this.customer._id,
        companyId: this.company._id,
        locationId: this.location._id,
        points,
      })
      this.$emit('sentPoints')
      this.$refs.dropdown.hide(true)
      this.$notify({
        title: 'On the way!',
        text: `${points} point${points > 1 ? 's' : ''} were sent.`,
      })
    },
  },
  async mounted() {},
}
</script>

<style scoped lang="scss">
a {
  text-transform: capitalize;
}
.dropdown-item {
  font-size: 14px;
}
</style>
