<template>
  <div class="MessageImage" style="width: 250px">
    <div class="col-12 mx-2 text-center" v-if="imageSource == null">
      <b-spinner small variant="primary" label="loading image" />
    </div>
    <img
      width="100%"
      height="auto"
      v-on:click="toggle"
      v-on:error="type = 'video'"
      class="image"
      :src="imageSource"
      :alt="alt"
      v-else-if="type == 'img'"
    />
    <video
      width="100%"
      height="auto"
      :src="imageSource"
      controls
      v-else-if="type == 'video'"
      v-on:error="type = 'error'"
      contain
    />
    <div v-else-if="type == 'error'">
      <div class="row text-center">
        <div class="col col-12">
          <i class="fe fe-image mr-1 icon-large"></i>
        </div>
        <div class="col col-12">
          <span>Unsupported Media</span>
        </div>
        <div class="col col-12">
          <span>{{ contentType }}</span>
        </div>
        <div class="col col-12 mt-2">
          <a :href="downloadLink" download class="download-button">
            <i class="far fa-cloud-arrow-down"></i> Download
          </a>
        </div>
      </div>
    </div>

    <div class="overlay" />
  </div>
</template>

<script>
export default {
  props: ['alt', 's3source', 'toggleDisabled'],
  data: () => ({
    imageSource: null,
    type: 'img',
    contentType: null,
  }),
  name: 'MessageImage',
  computed: {
    downloadLink() {
      return this.imageSource // Returns the image or video source for downloading
    },
  },
  methods: {
    toggle: function (event) {
      if (this.toggleDisabled != true) {
        let target = event.target
        target.classList.toggle('expand')
      }
    },
    errorHandler(error) {
      this.error = true
    },
    async loadSource() {
      let result = await this.$store.dispatch('s3/getObject', this.s3source)
      this.contentType = result.ContentType
      this.imageSource = `data:${result.ContentType};base64,${result.Body.toString('base64')}`
    },
  },
  mounted() {
    this.loadSource()
  },
}
</script>

<style lang="scss" scoped>
.image {
  cursor: pointer;
  width: 100%;
  &.expand {
    left: 50%;
    padding: 1rem;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    + .overlay {
      background-color: rgba(0, 0, 0, 0.5);
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 9;
    }
  }
}
.download-button {
  color: #007bff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  .far {
    margin-right: 0.5em;
  }
}
</style>

<style scoped lang="scss">
.icon-large {
  font-size: 3em;
}
</style>
