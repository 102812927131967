var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.shouldCelebrate)?_c('div',{staticClass:"celebration-banner form-control text-center"},[_c('span',[_vm._v("Great job responding quickly!")])]):_vm._e(),_c('div',{staticClass:"card-body",staticStyle:{"min-height":"55vh"},attrs:{"id":"timelineBody"},on:{"scroll":_vm.updateTimelineScroll}},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12"},[(_vm.selectTimelineEvents.length)?_c('div',{staticClass:"timeline",attrs:{"id":"timelineList"}},[_c('div',{staticClass:"container d-flex justify-content-center align-items-center"},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && _vm.selectTimelineScrollDirection == 'previous'),expression:"loading && selectTimelineScrollDirection == 'previous'"}],attrs:{"small":"","variant":"primary"}})],1),_vm._l((_vm.selectTimelineEvents),function(eventGroup){return _c('div',{key:eventGroup.timestamp},[(eventGroup)?_c('div',{staticClass:"text-center mb-2 mt-1"},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(eventGroup.timestamp)+" ")])]):_vm._e(),_vm._l((eventGroup.events),function(event){return _c('div',{key:event._id,staticClass:"row",class:{
                'justify-content-end':
                  event.user ||
                  event.type === 'sent-campaign' ||
                  event.type === 'sent-custom-question-list' ||
                  event.type === 'sent-promo',
              }},[(event.type === 'order')?_c('OrderEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"integration":_vm.selectIntegrationById(event.integration),"location":_vm.selectLocationById(event.location),"order":_vm.selectOrderById(event.order)}}):_vm._e(),(event.type === 'sent-campaign')?_c('CampaignEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"campaign":_vm.selectCampaignById(event.campaign)}}):(event.type === 'checkin' && event.location)?_c('CheckinEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"location":_vm.selectLocationById(event.location)}}):(event.type === 'earned-points' || event.type === 'spent-points')?_c('LoyaltyEvent',{attrs:{"id":`timeline-${event._id}`,"type":event.type,"created":event.created,"loyalty":_vm.selectLoyaltyById(event.loyalty),"pointLabel":_vm.company ? _vm.company.loyalty.pointLabel : null}}):(_vm.selectActiveConversation && event.type === 'left-feedback')?_c('FeedbackEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"survey":_vm.selectSurveyById(event.survey),"user":_vm.selectUserById(event.user),"conversationLastSurvey":_vm.selectActiveConversation.lastSurvey}}):(event.type === 'left-review')?_c('LeftReviewEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created}}):(
                  _vm.selectActiveConversation &&
                  (event.type === 'outgoing-message' || event.type === 'incoming-message')
                )?_c('MessageEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"message":_vm.selectMessageById(event.message),"user":_vm.selectUserById(event.user),"conversationLastMessage":_vm.selectActiveConversation.lastMessage}}):(event.type === 'redeem-promo')?_c('PromoRedeemEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"promo":_vm.selectPromoById(event.promo),"customerId":_vm.selectActiveCustomer ? _vm.selectActiveCustomer._id : null}}):(event.type === 'sent-promo')?_c('PromoSentEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"promo":_vm.selectPromoById(event.promo),"user":_vm.selectUserById(event.user)}}):(event.type === 'left-survey')?_c('SurveyEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created,"survey":_vm.selectSurveyById(event.survey),"location":_vm.selectLocationById(event.location)}}):(event.type === 'completed-custom-question-list')?_c('CompletedCustomQuestionListEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created}}):(event.type === 'sent-custom-question-list')?_c('SentCustomQuestionListEvent',{attrs:{"id":`timeline-${event._id}`,"created":event.created}}):_vm._e()],1)})],2)}),(_vm.loading && _vm.selectTimelineScrollDirection == 'next')?_c('div',{staticClass:"container d-flex justify-content-center align-items-center"},[_c('b-spinner',{attrs:{"small":"","variant":"primary"}})],1):_vm._e()],2):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quick-response-wrapper"},[_c('canvas',{staticStyle:{"position":"absolute","width":"90%","height":"75%"},attrs:{"id":"timelineConfetti"}})])
}]

export { render, staticRenderFns }