<template>
  <div>
    <button
      class="btn btn-sm bg-white border"
      title="Send Custom Question List"
      @click="sendList"
      :disabled="
        !activeConversation ||
        !activeConversation.lastSurvey ||
        activeConversation.lastSurvey.customQuestionList
      "
    >
      <i class="fas fa-envelope-open-text"></i>
    </button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CustomQuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'SendQuestionListButton',
  props: ['activeConversation'],
  methods: {
    ...CustomQuestionModule.mapActions(['sendCustomQuestionList']),
    async sendList() {
      try {
        await this.sendCustomQuestionList({ surveyId: this.activeConversation.lastSurvey._id })
      } catch (error) {
        this.$notify({
          type: 'error',
          title: `Failed to send Custom Question List for survey: ${this.activeConversation.lastSurvey._id}`,
        })
      }
    },
  },
}
</script>

<style scoped>
.btn:hover {
  background: var(--light) !important;
}
.btn:focus {
  outline: none;
  box-shadow: none;
  background: white !important;
}
</style>
