<template>
  <div class="message" v-if="promo">
    <div class="row mt-1 mb-1 message internal">
      <div class="col" :class="{ 'mr--2': promoUser.role, 'ml--2': !promoUser.role }">
        <div class="comment-body">
          <p class="comment-text text-capitalize">
            {{ promo.title }}
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Offer</span>
          </p>
          <div class="row">
            <div class="col-auto">
              <p class="comment-time">
                {{ created | moment('LT') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto mt-2">
        <Avatar
          v-if="promoUser.role"
          :img="promoUser.avatar"
          :first-name="promoUser.firstName"
          :last-name="promoUser.lastName"
          :name="promoUser.name"
          size="xs"
          status=""
        />
        <a
          href="javascript:;"
          v-if="!promoUser || !promoUser.role"
          class="btn btn-sm btn-rounded-circle btn-primary"
          ><i class="fe fe-tag icon-inner-adjust"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'
export default {
  name: 'PromoSentEvent',
  props: ['created', 'promo', 'user'],
  components: {
    Avatar,
  },
  computed: {
    promoUser() {
      return this.user ? this.user : {}
    },
  },
}
</script>
